// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./common.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
}

.card:hover {
    cursor: pointer;
}

.card {
    padding: 5px;
}

.media {
    height: 300px;
}

.centered-window {
    min-height: var(--window-height);
    height: -moz-fit-content;
    height: fit-content;
}

@media (max-width: 1200px) {
    .centered-window {
        min-height: var(--window-height-mobile);
        height: 100%;
    }

    .media {
        height: 200px;
    }
}

@media (max-width: 1024px) {


}

@media (max-width: 768px) {

}

@media (max-width: 600px) {
    .media {
        height: -moz-fit-content;
        height: fit-content;
        max-height: 300px;
    }
}

@media (max-width: 500px) {

}

@media (max-width: 375px) {


}

@media (max-width: 300px) {


}
`, "",{"version":3,"sources":["webpack://./src/styles/About.css"],"names":[],"mappings":"AAGA;IACI,4EAA4E;AAChF;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI;QACI,uCAAuC;QACvC,YAAY;IAChB;;IAEA;QACI,aAAa;IACjB;AACJ;;AAEA;;;AAGA;;AAEA;;AAEA;;AAEA;IACI;QACI,wBAAmB;QAAnB,mBAAmB;QACnB,iBAAiB;IACrB;AACJ;;AAEA;;AAEA;;AAEA;;;AAGA;;AAEA;;;AAGA","sourcesContent":["@import \"./variables.css\";\n@import \"./common.css\";\n\n.avatar {\n    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);\n}\n\n.card:hover {\n    cursor: pointer;\n}\n\n.card {\n    padding: 5px;\n}\n\n.media {\n    height: 300px;\n}\n\n.centered-window {\n    min-height: var(--window-height);\n    height: fit-content;\n}\n\n@media (max-width: 1200px) {\n    .centered-window {\n        min-height: var(--window-height-mobile);\n        height: 100%;\n    }\n\n    .media {\n        height: 200px;\n    }\n}\n\n@media (max-width: 1024px) {\n\n\n}\n\n@media (max-width: 768px) {\n\n}\n\n@media (max-width: 600px) {\n    .media {\n        height: fit-content;\n        max-height: 300px;\n    }\n}\n\n@media (max-width: 500px) {\n\n}\n\n@media (max-width: 375px) {\n\n\n}\n\n@media (max-width: 300px) {\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
