import React from 'react';
import {Box, Typography, Avatar, Card, CardMedia, CardContent} from '@mui/material';
import "../styles/About.css"

const materiel = [
    {
        nom: "Canon XF",
        image: "/assets/camera.png",
        description: "Le haut de gamme de la création vidéo.",
        link: "https://www.missnumerique.com/canon-camera-xf100-full-hd-p-33003.html"
    },
    {
        nom: "Canon M50 Mark II",
        image: "/assets/camera2.png",
        description: "Armé de son trepied, vous n'êtes pas prêt !",
        link: "https://www.canon.fr/cameras/eos-m50-mark-ii/specifications/"
    },
    {
        nom: "Objectif M50",
        image: "/assets/objectif.png",
        description: "Pour avoir toujours plus de détails.",
        link: "https://www.canon.fr/cameras/eos-m50-mark-ii/specifications/"
    }
];

const About = () =>
{
    return (
        <div>
            <Box className="centered-window second-color">
                <Avatar
                    alt="Votre Nom"
                    src="/assets/nulnulnul.jpeg"
                    className={"avatar"}
                    sx={{width: 200, height: 200}}
                />
                <Typography variant="h5" component="h2" fontWeight="bold" marginTop={5}>
                    Un petit mot
                </Typography>
                <Typography variant="body1" textAlign="center" marginTop={5} maxWidth={600}>
                    Père et fils passionnés par l'art de capturer des moments et de raconter des histoires à travers des
                    images et des vidéos. Nous mettons en œuvre notre savoir-faire afin de donner vie à vos rêves et de
                    préserver des souvenirs qui dureront toute une vie. !
                </Typography>
                <Typography variant="body2" textAlign="center" marginTop={1}>
                    Hâte de répondre à vos demandes de prestations !
                </Typography>
            </Box>
            {/*<Box className="centered-window">*/}
            {/*    <Typography variant="h2" component="h1" marginTop={2} textAlign={"center"}>*/}
            {/*        Notre matériel*/}
            {/*    </Typography>*/}
            {/*    <Box sx={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'center',*/}
            {/*        flexWrap: 'wrap',*/}
            {/*        marginY: 5,*/}
            {/*        gap: 5*/}
            {/*    }}>*/}
            {/*        {materiel.map((item) => (*/}
            {/*            <Card className={"card"} sx={{width: 345}} key={item.nom}*/}
            {/*                  onClick={() => window.open(item.link, "_blank")}>*/}
            {/*                <CardMedia*/}
            {/*                    className={"media"}*/}
            {/*                    component="img"*/}
            {/*                    image={item.image}*/}
            {/*                    alt={item.nom}*/}
            {/*                />*/}
            {/*                <CardContent>*/}
            {/*                    <Typography gutterBottom variant="h5" component="div">*/}
            {/*                        {item.nom}*/}
            {/*                    </Typography>*/}
            {/*                    <Typography variant="body2" color="text.secondary">*/}
            {/*                        {item.description}*/}
            {/*                    </Typography>*/}
            {/*                </CardContent>*/}
            {/*            </Card>*/}
            {/*        ))}*/}
            {/*    </Box>*/}
            {/*</Box>*/}
        </div>
    );

};

export default About;
;