import React, {useState, useRef} from 'react';
import Slider from "react-slick";
import {Typography, Box, Modal, IconButton} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "../styles/Home.css"

const photos = [
    {src: '/assets/achievements/image1.png', title: 'Image 1'},
    {src: '/assets/achievements/image2.png', title: 'Image 2'},
    {src: '/assets/achievements/image3.png', title: 'Image 3'},
    {src: '/assets/achievements/image4.png', title: 'Image 4'},
    {src: '/assets/achievements/image5.png', title: 'Image 5'},
    {src: '/assets/achievements/image6.png', title: 'Image 6'},
    {src: '/assets/achievements/image7.png', title: 'Image 7'},
    {src: '/assets/achievements/image8.png', title: 'Image 8'},
    {src: '/assets/achievements/image9.png', title: 'Image 9'},
    {src: '/assets/achievements/image10.png', title: 'Image 10'},
    {src: '/assets/achievements/image11.png', title: 'Image 11'},
    {src: '/assets/achievements/image12.png', title: 'Image 12'},
    {src: '/assets/achievements/image13.png', title: 'Image 13'},
    {src: '/assets/achievements/image14.png', title: 'Image 14'},
    {src: '/assets/achievements/image15.png', title: 'Image 15'},
    {src: '/assets/achievements/image16.png', title: 'Image 16'},
    {src: '/assets/achievements/image17.png', title: 'Image 17'},
    {src: '/assets/achievements/image18.png', title: 'Image 18'},
    {src: '/assets/achievements/image19.png', title: 'Image 19'},
    {src: '/assets/achievements/image20.png', title: 'Image 20'},
    {src: '/assets/achievements/image21.png', title: 'Image 21'},
    {src: '/assets/achievements/image22.png', title: 'Image 22'},
    {src: '/assets/achievements/image23.png', title: 'Image 23'},
    {src: '/assets/achievements/image24.png', title: 'Image 24'},
    {src: '/assets/achievements/image25.png', title: 'Image 25'},
    {src: '/assets/achievements/image26.png', title: 'Image 26'},
    {src: '/assets/achievements/image27.png', title: 'Image 27'},
    {src: '/assets/achievements/image28.png', title: 'Image 28'},
    {src: '/assets/achievements/image29.png', title: 'Image 29'},
    {src: '/assets/achievements/image30.png', title: 'Image 30'},
    {src: '/assets/achievements/image31.png', title: 'Image 31'},
    {src: '/assets/achievements/image32.png', title: 'Image 32'},
    {src: '/assets/achievements/image33.png', title: 'Image 33'},
    {src: '/assets/achievements/image34.png', title: 'Image 34'},
    {src: '/assets/achievements/image35.png', title: 'Image 35'},
    {src: '/assets/achievements/image36.png', title: 'Image 36'},
    {src: '/assets/achievements/image37.png', title: 'Image 37'},
    {src: '/assets/achievements/image38.png', title: 'Image 38'},
    {src: '/assets/achievements/image39.png', title: 'Image 39'},
    {src: '/assets/achievements/image40.png', title: 'Image 40'},
    {src: '/assets/achievements/image41.png', title: 'Image 41'},
    {src: '/assets/achievements/image42.png', title: 'Image 42'},
    {src: '/assets/achievements/image43.png', title: 'Image 43'},
    {src: '/assets/achievements/image44.png', title: 'Image 44'}
];

const Home = () =>
{

    const [open, setOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);
    const achievementsRef = useRef(null);

    const handleOpen = (img) =>
    {
        setOpen(true);
        setSelectedImg(img);
    };

    const handleClose = () => setOpen(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 768, // Taille d'écran de 768px et moins
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024, // Taille d'écran de 1024px et moins
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200, // Taille d'écran de 1200px et moins
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1400, // Taille d'écran de 1200px et moins
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1600, // Taille d'écran de 1600px et moins
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Box className="centered-window">
                <Typography variant="h2" gutterBottom className={"home-title"}>
                    Dadson Prod
                </Typography>
                <Typography variant="subtitle1">
                    Photographies et Vidéos
                </Typography>
                <IconButton onClick={() => achievementsRef.current.scrollIntoView({behavior: 'smooth'})}>
                    <KeyboardArrowDownIcon style={{fontSize: 'larger'}}/>
                </IconButton>
            </Box>
            <Box ref={achievementsRef}/>
            <Box className={"achievements"}>
                <Typography variant="h4" component="h4" marginBottom={5} textAlign={"center"}>
                    Nos dernières réalisations
                </Typography>
                <Slider {...settings} className={"slider"}>
                    {photos.map((photo) => (
                        <div key={photo.id}>
                            <img src={photo.src} alt={photo.alt} style={{}} onClick={() => handleOpen(photo.src)}/>
                        </div>
                    ))}
                </Slider>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backdropFilter: 'blur(3px)',
                }}
            >
                <Box sx={{outline: 'none'}}>
                    <img src={selectedImg} alt="Enlarged pic"
                         style={{
                             maxWidth: '100%',
                             maxHeight: '90vh',
                             borderRadius: '10px'
                         }}/>
                </Box>
            </Modal>
<Box className="centered-window" sx={{ backgroundColor: '#fff' }}>

    <Typography variant="h4" component="h4" marginTop={5} textAlign={"center"}>
        Photographie d'évènements
    </Typography>
    <Typography variant="subtitle1" component="subtitle1" marginTop={5} textAlign={"center"}>
        Un mariage ? Un anniversaire ? Une conférence ou tout autre événement ? <br/>Nous sommes là
        pour immortaliser chaque instant !
    </Typography>

    <Typography variant="h4" component="h4" marginTop={5}>
        Séances Portrait
    </Typography>
    <Typography variant="subtitle1" component="subtitle1" marginY={5} textAlign={"center"}>
        Mettez-vous en avant avec des séances de portrait personnalisées.<br/> En studio tout comme à
        l'extérieur, peu importe l'ocasion !
    </Typography>

    {/* Section ajoutée pour la réalisation vidéo */}
    <Typography variant="h4" component="h4" marginTop={5} textAlign={"center"}>
        Réalisation Vidéo
    </Typography>
    <Typography variant="subtitle1" component="subtitle1" marginTop={5} textAlign={"center"}>
        Racontez votre histoire avec une vidéo captivante !<br /> Que ce soit pour un événement familial, un
        projet professionnel ou une création artistique, nous donnons vie à vos idées.
    </Typography>

</Box>

        </div>
    );
};

export default Home;