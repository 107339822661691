// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-window {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: var(--window-height);
}

.centered-halfwindow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(var(--window-height) / 1.5);
}

.second-color {
    background-color: var(--secondary-color);
    color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/styles/common.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,wCAAwC;AAC5C;;AAEA;IACI,wCAAwC;IACxC,mBAAmB;AACvB","sourcesContent":[".centered-window {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: var(--window-height);\n}\n\n.centered-halfwindow {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: calc(var(--window-height) / 1.5);\n}\n\n.second-color {\n    background-color: var(--secondary-color);\n    color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
