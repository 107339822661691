// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: var(--secondary-color);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: var(--secondary-color);
}

::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.drawer {
    width: var(--drawer-width);
    height: var(--drawer-height);
    background-color: var(--secondary-color);
    color: var(--white);
    font-size: 1.4em;
}

.app-bar {
    background-color: var(--secondary-color);
}

.App {
    background-color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAGA;IACI,wCAAwC;AAC5C;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,wCAAwC;IACxC,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":["@import \"variables.css\";\n\n\nbody {\n    background-color: var(--secondary-color);\n}\n\n::-webkit-scrollbar {\n    width: 5px;\n}\n\n::-webkit-scrollbar-track {\n    background: var(--secondary-color);\n}\n\n::-webkit-scrollbar-thumb {\n    background-color: #555;\n    border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n    background: #555;\n}\n\n\n.drawer {\n    width: var(--drawer-width);\n    height: var(--drawer-height);\n    background-color: var(--secondary-color);\n    color: var(--white);\n    font-size: 1.4em;\n}\n\n.app-bar {\n    background-color: var(--secondary-color);\n}\n\n.App {\n    background-color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
