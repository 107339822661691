// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./common.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: var(--secondary-color);
    color: var(--white);
    text-align: center;
    font-size: 1.0em;
}

.footer a {
    color: var(--white);
    transition: 0.1s;
}

.footer a:hover {
    cursor: pointer;
    color: var(--grey);
}

@media (max-width: 600px) {
    .footer {
        margin-top: 0;
        font-size: 0.8em;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAGA;IACI,wCAAwC;IACxC,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;QACb,gBAAgB;IACpB;AACJ","sourcesContent":["@import \"./variables.css\";\n@import \"./common.css\";\n\n.footer {\n    background-color: var(--secondary-color);\n    color: var(--white);\n    text-align: center;\n    font-size: 1.0em;\n}\n\n.footer a {\n    color: var(--white);\n    transition: 0.1s;\n}\n\n.footer a:hover {\n    cursor: pointer;\n    color: var(--grey);\n}\n\n@media (max-width: 600px) {\n    .footer {\n        margin-top: 0;\n        font-size: 0.8em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
