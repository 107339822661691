import React, {useState} from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Hidden
} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import HomeIcon from '@mui/icons-material/Home';
import PhotoLibrary from '@mui/icons-material/PhotoLibrary';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from './pages/Home';
import Contact from "./pages/Contact";
import About from "./pages/About";
import Achievements from "./pages/Achievements";
import Footer from "./pages/Footer";

import './styles/App.css';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Fredoka'
        ].join(','),
    },
});

function App()
{
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () =>
    {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <List className="drawer">
                <ListItem sx={{my: 2}} button component={Link} to="/" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                        <HomeIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    Accueil
                </ListItem>
                <ListItem sx={{my: 2}} button component={Link} to="/achievements" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                        <PhotoLibrary sx={{color: 'white'}}/>
                    </ListItemIcon>
                    Réalisations
                </ListItem>
                <ListItem sx={{my: 2}} button component={Link} to="/about" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                        <InfoIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    À propos
                </ListItem>
                <ListItem sx={{my: 2}} button component={Link} to="/contact" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                        <ContactMailIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    Contact
                </ListItem>
            </List>
        </div>
    );

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <div className="App">
                    <AppBar position="sticky">
                        <Toolbar className="app-bar">
                            <Typography variant="h6" style={{flexGrow: 1}}>
                                <Link to="/" style={{textDecoration: 'none', color: 'inherit'}}>
                                    Dadson Prod
                                </Link>
                            </Typography>
                            <Hidden smDown>
                                <Button color="inherit" component={Link} to="/">Accueil</Button>
                                <Button color="inherit" component={Link} to="/achievements">Réalisations</Button>
                                <Button color="inherit" component={Link} to="/about">A propos</Button>
                                <Button color="inherit" component={Link} to="/contact">Contact</Button>
                            </Hidden>
                            <Hidden smUp>
                                <IconButton color="inherit" edge="end" onClick={handleDrawerToggle}>
                                    <MenuIcon/>
                                </IconButton>
                            </Hidden>
                        </Toolbar>
                    </AppBar>
                    <nav>
                        <Hidden smUp implementation="css">
                            <Drawer
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{keepMounted: true}}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/achievements" element={<Achievements/>}/>
                    </Routes>
                </div>
                <Footer/>
            </Router>
        </ThemeProvider>
    );
}

export default App;
