import {Typography, Grid, Card, CardMedia, Box, Modal} from '@mui/material';
import React, {useState} from 'react';
import "../styles/Achievements.css"

const photos = [
    {src: '/assets/achievements/image1.png', title: 'Image 1'},
    {src: '/assets/achievements/image2.png', title: 'Image 2'},
    {src: '/assets/achievements/image3.png', title: 'Image 3'},
    {src: '/assets/achievements/image4.png', title: 'Image 4'},
    {src: '/assets/achievements/image5.png', title: 'Image 5'},
    {src: '/assets/achievements/image6.png', title: 'Image 6'},
    {src: '/assets/achievements/image7.png', title: 'Image 7'},
    {src: '/assets/achievements/image8.png', title: 'Image 8'},
    {src: '/assets/achievements/image9.png', title: 'Image 9'},
    {src: '/assets/achievements/image10.png', title: 'Image 10'},
    {src: '/assets/achievements/image11.png', title: 'Image 11'},
    {src: '/assets/achievements/image12.png', title: 'Image 12'},
    {src: '/assets/achievements/image13.png', title: 'Image 13'},
    {src: '/assets/achievements/image14.png', title: 'Image 14'},
    {src: '/assets/achievements/image15.png', title: 'Image 15'},
    {src: '/assets/achievements/image16.png', title: 'Image 16'},
    {src: '/assets/achievements/image17.png', title: 'Image 17'},
    {src: '/assets/achievements/image18.png', title: 'Image 18'},
    {src: '/assets/achievements/image19.png', title: 'Image 19'},
    {src: '/assets/achievements/image20.png', title: 'Image 20'},
    {src: '/assets/achievements/image21.png', title: 'Image 21'},
    {src: '/assets/achievements/image22.png', title: 'Image 22'},
    {src: '/assets/achievements/image23.png', title: 'Image 23'},
    {src: '/assets/achievements/image24.png', title: 'Image 24'},
    {src: '/assets/achievements/image25.png', title: 'Image 25'},
    {src: '/assets/achievements/image26.png', title: 'Image 26'},
    {src: '/assets/achievements/image27.png', title: 'Image 27'},
    {src: '/assets/achievements/image28.png', title: 'Image 28'},
    {src: '/assets/achievements/image29.png', title: 'Image 29'},
    {src: '/assets/achievements/image30.png', title: 'Image 30'},
    {src: '/assets/achievements/image31.png', title: 'Image 31'},
    {src: '/assets/achievements/image32.png', title: 'Image 32'},
    {src: '/assets/achievements/image33.png', title: 'Image 33'},
    {src: '/assets/achievements/image34.png', title: 'Image 34'},
    {src: '/assets/achievements/image35.png', title: 'Image 35'},
    {src: '/assets/achievements/image36.png', title: 'Image 36'},
    {src: '/assets/achievements/image37.png', title: 'Image 37'},
    {src: '/assets/achievements/image38.png', title: 'Image 38'},
    {src: '/assets/achievements/image39.png', title: 'Image 39'},
    {src: '/assets/achievements/image40.png', title: 'Image 40'},
    {src: '/assets/achievements/image41.png', title: 'Image 41'},
    {src: '/assets/achievements/image42.png', title: 'Image 42'},
    {src: '/assets/achievements/image43.png', title: 'Image 43'},
    {src: '/assets/achievements/image44.png', title: 'Image 44'}
];
const Achievements = () =>
{
    const [open, setOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);

    const handleOpen = (img) =>
    {
        setOpen(true);
        setSelectedImg(img);
    };

    const handleClose = () => setOpen(false);

    return (
        <>
            <Box className="centered-halfwindow">
                <Typography variant="h2" component="h2" marginTop={5}>
                    Réalisations
                </Typography>
                <Typography variant="h4" component="h4" marginTop={5} textAlign={"center"}>
                    Une collection visuelle pour le plaisir de vos pupilles
                </Typography>

            </Box>
            <Grid container spacing={4} paddingX={5} paddingY={5} justifyContent="center">
                {photos.map((photo, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            borderRadius: '10px'
                        }}>
                            <CardMedia
                                className="card-media"
                                component="img"
                                image={photo.src}
                                alt={photo.title}
                                sx={{
                                    objectFit: 'cover',
                                    width: '500px',
                                    height: '500px',
                                    maxHeight: '500px',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {transform: 'scale(1.1)'},
                                }}
                                onClick={() => handleOpen(photo.src)}
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backdropFilter: 'blur(3px)',
                }}
            >
                <Box sx={{outline: 'none'}}>
                    <img src={selectedImg} alt="Enlarged pic"
                         style={{
                             maxWidth: '100%',
                             maxHeight: '90vh',
                             borderRadius: '10px'
                         }}/>
                </Box>
            </Modal>
        </>
    );
};
export default Achievements;