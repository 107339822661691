// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --drawer-width: 240px;
    --drawer-height: calc(100vh - 16px);
    --window-height: calc(100vh - 64px);
    --window-height-mobile: calc(100vh - 56px);

    --primary-color: #d4e9e9;
    --secondary-color: #232325;
    --white: #ffffff;
    --grey: #808080;

}`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mCAAmC;IACnC,mCAAmC;IACnC,0CAA0C;;IAE1C,wBAAwB;IACxB,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;;AAEnB","sourcesContent":[":root {\n    --drawer-width: 240px;\n    --drawer-height: calc(100vh - 16px);\n    --window-height: calc(100vh - 64px);\n    --window-height-mobile: calc(100vh - 56px);\n\n    --primary-color: #d4e9e9;\n    --secondary-color: #232325;\n    --white: #ffffff;\n    --grey: #808080;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
