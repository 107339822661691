import React from 'react';
import {Box, Typography, Container, Grid, Link} from '@mui/material';
import "../styles/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTokIcon from "@mui/icons-material/MusicNote";

const Footer = () =>
{
    return (
        <Box component="footer" className={"footer"}>
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent="center">
                    <Grid item>
                        <Link href="/" style={{textDecoration: 'none'}}>
                            Accueil
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/achievements" style={{textDecoration: 'none'}}>
                            Réalisations
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/about" style={{textDecoration: 'none'}}>
                            A propos
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/contact" style={{textDecoration: 'none'}}>
                            Nous contacter
                        </Link>
                    </Grid>
                </Grid>
                <Box textAlign="center" padding={2}>
                    <Box display="flex" justifyContent="center" alignItems="center" gap={6}>
                        <Link href="mailto:contact@dadson-prod.fr" color="inherit">
                            <EmailIcon/>
                        </Link>
                        <Link href="https://www.instagram.com/dadson_prod" color="inherit">
                            <InstagramIcon/>
                        </Link>
                        <Link href="https://www.tiktok.com/dadson_prod" color="inherit">
                            <TikTokIcon/>
                        </Link>
                    </Box>
                </Box>
                <Typography variant="body2" align="center" marginTop={0}>
                    © {new Date().getFullYear()} Dadson Prod - Tous droits réservés.
                </Typography>
            </Container>

        </Box>
    );
};


export default Footer;
