import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import TikTokIcon from "@mui/icons-material/MusicNote";
import '../styles/Contact.css';

const ContactInfo = () => {
    // Fonction pour ouvrir les liens dans les applications mobiles si disponible
    const handleSocialClick = (platform) => {
        if (/Mobi/i.test(navigator.userAgent)) {
            // Vérifier si l'utilisateur est sur un appareil mobile
            let url = '';
            if (platform === 'instagram') {
                url = 'instagram://user?username=dadson_prod';
            } else if (platform === 'tiktok') {
                url = 'snssdk1233://user/profile/dadson_prod';
            }
            window.open(url);
        } else {
            // Sur desktop, ouvrir le lien web normal
            window.open(platform === 'instagram' ? 'https://www.instagram.com/dadson_prod' :
                'https://www.tiktok.com/@dadson_prod');
        }
    }

    return (
        <Box className="centered-window" display="flex" flexDirection="column" justifyContent="center"
             alignItems="center">
            <Typography variant="h4" gutterBottom>
                Nous contacter
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                <Button startIcon={<EmailIcon/>} href="mailto:contact@dadson-prod.fr" variant="outlined"
                        color="inherit">
                    Email
                </Button>
                <Button startIcon={<InstagramIcon/>} onClick={() => handleSocialClick('instagram')} variant="outlined"
                        color="inherit">
                    Instagram
                </Button>
                <Button startIcon={<TikTokIcon/>} onClick={() => handleSocialClick('tiktok')} variant="outlined"
                        color="inherit">
                    TikTok
                </Button>
            </Box>
        </Box>
    );
};

export default ContactInfo;

